import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';

export default function Home({ data }) {
  setName('Home');

  return (
    <>
      <div
        css={css`
          background-color: var(--orange);
          padding: var(--margin);

          > div > p, > div > h3 {
            font-family: 'West Space', 'Building Block', sans-serif !important;
            margin-bottom: var(--gap);

            @media (max-width: 512px) {

            }
          }

          > div > .rule {
            background-color: var(--black);
          }
        `}
      >
        <BlockContent
          className="type--50--important"
          content={data.page._rawContent}
        />
      </div>
    </>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – Home`} />
}

export const query = graphql`
  query {
    page: sanityHome(_id: { regex: "/^(drafts\\.)?home$/" }) {
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
